<template>
  <div class="problemList">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <!-- 论坛信息模块 -->
          <div class="forumDetails_box">
            <div class="info_box">
              <div class="img">
                <img :src="publisherUserInfo.headimg" alt="" />
                <!-- <el-avatar size="medium" src="../../assets/index/login.png"></el-avatar> -->
              </div>
              <div class="info">
                <div class="info-title">

                  <p>
                    {{ publisherUserInfo.nickname ? publisherUserInfo.nickname : publisherUserInfo.phone }}
                  </p>
                  <span class="owner">楼主</span>
                </div>

                <p class="create_time">{{ info._create_time }}

                  <span class="feedback" @click="handleCommand('feedback' , info.title , '论坛')">
                    <i class="el-icon-edit-outline" style="color: #231815"></i>反馈
                  </span>
                </p>
                <div class="title_box">
                  <p v-html="info.title"></p>
                </div>
              </div>
            </div>
            <div class="centen_box">
              <div class="banner">
                <div v-html="info.content">
                </div>
              </div>
              <div class="func">
                <ul>
                  <li @click="clickCollect">
                    <div>
                      <img :src="require('../../assets/images/me/收藏@3x.png')" alt="" v-if="!info.is_collect" />
                      <img style="width: 22px; height: 22px" src="../../assets/images/me/ysc.png" v-if="info.is_collect" alt />
                    </div>
                    <div :class="{collectbgc:info.is_collect}">收藏：{{ info.collect_num }}</div>
                  </li>
                  <li>
                    <div>
                      <img :src="require('../../assets/images/me/预览@3x.png')" alt="" />
                    </div>
                    <div>预览：{{ info.view_num }}</div>
                  </li>
                  <li @click="clickPraise">
                    <div>
                      <img :src="require('../../assets/images/me/点赞@3x.png')" v-if="!info.is_praise" alt="" />
                      <img style="width: 22px; height: 22px" src="../../assets/images/me/ydz.png" v-if="info.is_praise" alt />
                    </div>
                    <div :class="{praisebgc:info.is_praise}">点赞：{{ info.praise_num }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 评论模块 -->
          <div class="commentList_box" v-if="info.id">
            <comment type="forum" :where="{id: info.id,}" v-loading="loading">
              <p style="text-align: center;color:#bcbcbc;margin-bottom: 20px;"  slot="empty">暂无评论</p>
            </comment>
          </div>
        </div>
        <div class="content-right">
          <div class="tag">
            <p>相关标签</p>
            <ul>
              <li v-for="(item, index) in tagList" :key="index" :style="{ background: item.color }">
                {{ item.title }}
              </li>
            </ul>
          </div>
          <Recommend class="border"></Recommend>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  forumDetail,
  collect,
  praise,
} from "@/api/forum.js"
import Recommend from './components/Recommend'
import Comment from "@/views/api/com/comment";


export default {
  components: {
    Comment,
    Recommend
  },
  data () {
    return {
      loading: false,
      visible: false,
      user_id: localStorage.getItem("user_id") || "",
      itemValue: "",
      page: 1,
      pageSize: 10,
      total: 0,
      id: "",
      info: {},
      publisherUserInfo: {},

      tagList: [],

      publishValue: "",
      commentList: [],
    }
  },
  watch: {
    $route (to, from) {
      console.log(99999)
      this.id = this.$route.query.id
      this.getForumDetalis()
    },
  },
  created () {
    this.id = this.$route.query.id
    this.getForumDetalis()
  },
  methods: {
    // 查看详情
    getForumDetalis () {
      forumDetail({ id: this.id })
        .then(({ data }) => {
          this.info = data.info
          this.publisherUserInfo = {...((this.$store.state.defaultData || {}).userInfo || {}),...data.publisher}


          this.tagList = data.info.tags
          console.log(data, "帖子详情成功")
        })
        .catch((err) => {
          console.log(err, "帖子详情失败")
        })
    },
    // 收藏 
    clickCollect () {
      if (this.userType == 0) {
        this.$store.state.showBinding = true
        return
      }
      collect({ id: this.info.id })
        .then((res) => {
          console.log(res)
          this.info.collect_num = res.data.collect_count
          this.info.is_collect = res.data.is_collect
          this.$message.success(res.msg)
        })
        .catch((err) => {
          console.log(err)

        })
    },
    // 点赞
    clickPraise () {
      if (this.userType == 0) {
        this.$store.state.showBinding = true
        return
      }
      praise({ id: this.info.id })
        .then((res) => {
          console.log(res)
          this.info.praise_num = res.data.praise_count
          this.info.is_praise = res.data.is_praise
          this.$message.success(res.msg)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
};
</script>

<style lang="less" scoped>
 li {
    list-style-type: none;
  } 
.border {
  
  border: 1px solid #bcbcbc;
}
.praisebgc {
  color: #ff623f !important;
}
.collectbgc {
  color: #fac83e !important;
}
::v-deep {
  .el-button--primary.is-plain:focus,
  .el-button--primary.is-plain:hover {
    background: #fff;
    border-color: #409eff;
    color: #409eff;
  }
  .ipt {
    .el-input {
      width: 80%;
    }
  }
}

.owner {
  padding: 0.3% 1.5%;
  background: #00957e;
  border-radius: 5px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 2%;
}
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    width: 70%;
    .forumDetails_box {
      border: 1px solid #bcbcbc;
      padding: 1.5% 5.5% 4% 2%;
      .info_box {
        display: flex;
        .img {
          width: 55px;
          height: 55px;
          min-width: 55px;
          border-radius: 50%;
          overflow: hidden;
          img {
            display: flex;
            width: 100%;
            height: 100%;
          }
        }
        .info {
          margin: 1% 0 0 1.5%;
          width: 100%;
          .create_time {
            font-size: 16px;
          }

          .feedback {
            margin-left: 2rem;
            font-size: 16px;
            cursor: pointer;
          }
          p {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 33px;
          }
          div {
            display: flex;
            align-items: center;
          }
          .title_box {
            width: 100%;

            display: flex;
            justify-content: space-between;
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #1e1e1e;
              line-height: 20px;
              white-space: nowrap;
              cursor: pointer;
              .el-icon-edit-outline {
                color: #8c898c;
                margin-right: 5px;
                font-size: 16px;
              }
            }
          }
        }
      }
      .centen_box {
        padding: 2% 0 0 3%;
        .banner {
          overflow: auto;
        }
        .conten_info {
          margin: 5% 0 0 4%;
          p {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
          }
          div {
            margin-top: 1.3%;
          }
        }
        .func {
          margin-left: 4%;
          width: 100%;

          ul {
            width: 100%;

            display: flex;
            align-items: center;
            li {
              display: flex;
              align-items: center;
              margin: 3% 5% 0 0;
              &:nth-child(1),
              &:nth-child(3) {
                cursor: pointer;
              }
              div {
                &:nth-child(1) {
                  display: inline-block;
                  width: 18px;
                  height: 18px;
                  margin-right: 6px;
                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                  }
                }
                &:nth-child(2) {
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #777777;
                  white-space: nowrap;
                  margin-top: 6px;
                }
              }
            }
          }
        }
      }
    }
    .commentList_box {
      .evaluate {
        margin-top: 5%;
        .pagination {
          text-align: center;
          margin: 2.5rem 0;
        }
        /deep/ .publish {
          display: flex;
          padding: 0 8%;
          .el-input__inner {
            height: 32px;
            line-height: 32px;
            border-radius: 5px 0 0 5px;
            border-right: none;
          }

          .el-button {
            height: 32px !important;
            width: 53px;
            padding: 0;
            text-align: center;
            box-sizing: border-box;
            border-radius: 0 5px 5px 0;
            border: 1px solid #00957e;
            border-left: none;
            background: #00957e;
            .el-icon-search {
              color: #fff;
            }
          }
          .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #00957e;
          }
          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #00957e;
            border-color: #00957e;
          }
        }
        .evaluate-item {
          display: flex;
          align-items: center;
          border: 1px solid #bcbcbc;
          border-top: 0;
          &:nth-child(1) {
            border-top: 1px solid #bcbcbc;
          }
          padding: 1% 4% 1% 3.5%;

          .text {
            flex: 8;
            // width: 100%;
            display: flex;
            justify-content: space-between;
            margin-left: 1rem;
            background: #ffffff;
            padding: 1rem 1rem 0.5rem;
            font-size: 14px;
            .text-user {
              color: #525252;
              margin-bottom: 0.5rem;
            }
          }
          .user {
            text-align: center;
            font-size: 10px;
          }
          .time {
            display: flex;
            align-items: flex-end;
            text-align: right;
            color: #525252;
            font-size: 12px;
            margin-left: 0.5rem;
            .buttom {
              margin-top: 2rem;
              .el-button {
                padding: 0;
              }
            }
            .reply {
              margin-left: 0.8rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .content-right {
    width: 27%;
    .tag {
      border: 1px solid #bcbcbc;
      padding: 3% 4% 10% 6%;
      margin-bottom: 5%;
      P {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #8c8c8c;
        line-height: 20px;
        margin: 4% 0 3%;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          padding: 4px 0.6rem;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
          // background-color: pink;
          // margin-right: 3%;
          word-break: break-all;
          border-radius: 3px;
          margin: 0 3% 10px 0;
        }
      }
    }
    .margin {
      border: 1px solid #bcbcbc;
      padding: 3% 4% 10%;
      margin-top: 40px;
    }
  }
}
</style>